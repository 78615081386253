
import { initializeApp } from "https://www.gstatic.com/firebasejs/9.17.2/firebase-app.js";
import { getAnalytics } from "https://www.gstatic.com/firebasejs/9.17.2/firebase-analytics.js";
import { getAuth } from "https://www.gstatic.com/firebasejs/9.17.2/firebase-auth.js";

const firebaseConfig = {
 apiKey: "AIzaSyBY5TV2Xc3ESFmWiwL33v9mO7TAQ-ceTWs",
  authDomain: "app-ki-yojana.firebaseapp.com",
  projectId: "app-ki-yojana",
  storageBucket: "app-ki-yojana.appspot.com",
  messagingSenderId: "652011155629",
  appId: "1:652011155629:web:badc12300cf86be6aed624"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { app, analytics, auth };
