import React from 'react';
import Form from '@rjsf/core';
import { customizeValidator } from '@rjsf/validator-ajv8';
import Ajv from 'ajv';
const ajv = new Ajv();
const validator = customizeValidator({ ajv });

const MyForm = () => {
  const schema = {
    type: 'object',
    required: ['selectThe'],
    properties: {
      selectThe: {
        type: 'string',
        title: 'Select the category:',
        enum: ['Individual', 'Company/ Limited Liability Partnership (LLP)'],
        points: [],
      },
    },
    dependencies: {
      hasThe: { oneOf: [] },
      haveYou: {
        oneOf: [
          {
            properties: {
              haveYou: { enum: ['No'] },
              hasThe: {
                type: 'string',
                title: 'Has the proposed objective and deliverable received funding support from any other agency?',
                enum: ['Yes', 'No'],
                points: [],
              },
            },
            required: ['hasThe'],
          },
        ],
      },
      areYouAPromoter: {
        oneOf: [
          {
            properties: {
              areYouAPromoter: { enum: ['No'] },
              haveYou: {
                type: 'string',
                title: 'Have you earlier availed of the benefits under this scheme?',
                enum: ['Yes', 'No'],
                points: [],
              },
            },
            required: ['haveYou'],
          },
        ],
      },
      doYou: {
        oneOf: [
          {
            properties: {
              doYou: { enum: ['Yes'] },
              areYouAPromoter: {
                type: 'string',
                title: 'Are you a promoter shareholder of a Biotech company or one of the partners in a Biotech LLP?',
                enum: ['Yes', 'No'],
                points: [],
              },
            },
            required: ['areYouAPromoter'],
          },
        ],
      },
      areYouAProject: {
        oneOf: [
          {
            properties: {
              areYouAProject: { enum: ['Yes'] },
              doYou: {
                type: 'string',
                title: 'Do you have the potential to commercialize innovative ideas?',
                enum: ['Yes', 'No'],
                points: [],
              },
            },
            required: ['doYou'],
          },
        ],
      },
      areYouA: {
        oneOf: [
          {
            properties: {
              areYouA: { enum: ['Yes'] },
              areYouAProject: {
                type: 'string',
                title: 'Are you a Project Leader?',
                enum: ['Yes', 'No'],
                points: [],
              },
            },
            required: ['areYouAProject'],
          },
        ],
      },
      areYou: {
        oneOf: [
          {
            properties: {
              areYou: { enum: ['Yes'] },
              areYouA: {
                type: 'string',
                title: 'Are you a Scientist/Faculty/Research Scholar/Graduate in any discipline and incubating/intend to incubate at a Bio Incubator?',
                enum: ['Yes', 'No'],
                points: [],
              },
            },
            required: ['areYouA'],
          },
        ],
      },
      hasTheProposed: { oneOf: [] },
      hasTheApplicant: {
        oneOf: [
          {
            properties: {
              hasTheApplicant: { enum: ['No'] },
              hasTheProposed: {
                type: 'string',
                title: 'Has the proposed objective and deliverable received funding support from any other agency?',
                enum: ['Yes', 'No'],
                points: [],
              },
            },
            required: ['hasTheProposed'],
          },
        ],
      },
      isTheApplicant: {
        oneOf: [
          {
            properties: {
              isTheApplicant: { enum: ['No'] },
              hasTheApplicant: {
                type: 'string',
                title: 'Has the applicant company/LLP earlier availed of the benefits under this scheme?',
                enum: ['Yes', 'No'],
                points: [],
              },
            },
            required: ['hasTheApplicant'],
          },
        ],
      },
      hasTheCompanyllp: {
        oneOf: [
          {
            properties: {
              hasTheCompanyllp: { enum: ['Yes'] },
              isTheApplicant: {
                type: 'string',
                title: 'Is the applicant company, in which any promoter holding more than or equal to 20% of the shares, a co-promoter of another ineligible company or a partner in another ineligible LLP?',
                enum: ['Yes', 'No'],
                points: [],
              },
            },
            required: ['isTheApplicant'],
          },
        ],
      },
      canThe: {
        oneOf: [
          {
            properties: {
              canThe: { enum: ['Yes'] },
              hasTheCompanyllp: {
                type: 'string',
                title: 'Has the company/LLP the potential to commercialize the innovative ideas?',
                enum: ['Yes', 'No'],
                points: [],
              },
            },
            required: ['hasTheCompanyllp'],
          },
        ],
      },
      doesThe: {
        oneOf: [
          {
            properties: {
              doesThe: { enum: ['No'] },
              canThe: {
                type: 'string',
                title: 'Can the Company/LLP be incubated at an incubator?',
                enum: ['Yes', 'No'],
                points: [],
              },
            },
            required: ['canThe'],
          },
          {
            properties: {
              doesThe: { enum: ['Yes'] },
              hasTheCompanyllp: {
                type: 'string',
                title: 'Has the company/LLP the potential to commercialize the innovative ideas?',
                enum: ['Yes', 'No'],
                points: [],
              },
            },
            required: ['hasTheCompanyllp'],
          },
        ],
      },
      isTheProject: {
        oneOf: [
          {
            properties: {
              isTheProject: { enum: ['Yes'] },
              doesThe: {
                type: 'string',
                title: 'Does the Company/LLP have its own in-house R and D facility and is functional and adequate to execute the project?',
                enum: ['Yes', 'No'],
                points: [],
              },
            },
            required: ['doesThe'],
          },
        ],
      },
      doesAny: {
        oneOf: [
          {
            properties: {
              doesAny: { enum: ['Yes'] },
              isTheProject: {
                type: 'string',
                title: 'Is the project Leader a shareholder in the applicant company?',
                enum: ['Yes', 'No'],
                points: [],
              },
            },
            required: ['isTheProject'],
          },
        ],
      },
      isTheDate: {
        oneOf: [
          {
            properties: {
              isTheDate: { enum: ['No'] },
              doesAny: {
                type: 'string',
                title: 'Does any Indian/Indian company own a minimum of 51% of the capital of the company?',
                enum: ['Yes', 'No'],
                points: [],
              },
            },
            required: ['doesAny'],
          },
        ],
      },
      isThe: {
        oneOf: [
          {
            properties: {
              isThe: { enum: ['Yes'] },
              isTheDate: {
                type: 'string',
                title: 'Is the date of incorporation of the Company/LLP earlier than 5 years?',
                enum: ['Yes', 'No'],
                points: [],
              },
            },
            required: ['isTheDate'],
          },
        ],
      },
      selectThe: {
        oneOf: [
          {
            properties: {
              selectThe: { enum: ['Individual'] },
              areYou: {
                type: 'string',
                title: 'Are you a citizen of India?',
                enum: ['Yes', 'No'],
                points: [],
              },
            },
            required: ['areYou'],
          },
          {
            properties: {
              selectThe: { enum: ['Company/ Limited Liability Partnership (LLP)'] },
              isThe: {
                type: 'string',
                title: 'Is the applicant Company/LLP registered under the Indian Companies Act, 1956/2013?',
                enum: ['Yes', 'No'],
                points: [],
              },
            },
            required: ['isThe'],
          },
        ],
      },
    },
  };

  const uiSchema = {
    selectThe: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
    areYou: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
    areYouA: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
    areYouAProject: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
    doYou: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
    areYouAPromoter: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
    haveYou: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
    hasThe: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
    hasTheApplicant: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
    hasTheProposed: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
    hasTheCompanyllp: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
    canThe: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
    doesThe: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
    isTheProject: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
    doesAny: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
    isTheDate: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
    isThe: { 'ui:widget': 'radio', 'ui:options': { inline: true } },
  };
  const onSubmit = ({ formData }) => {
    console.log('Form data:', formData);
  };

  const onError = (errors) => {
    console.log('Form errors:', errors);
  };

  return (
    <div>
      <h1>Sample Form</h1>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        onSubmit={onSubmit}
        onError={onError}
        validator={validator} // Specify the validator
      />
    </div>
  );
};

export default MyForm;
