import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import Settingpop from './Settingpop';
import HomeFooter from "./HomeFooter";
import LoadingN from './Loader';
import { API_BASE_URL } from './Config';
import NotificationExample, { notify } from './NotificationExample'; // Import NotificationExample and notify

const Bookmark = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBy, setFilterBy] = useState('scheme_name');
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(page);
  }, [page]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollTop + windowHeight >= documentHeight - 100 && !loading && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, hasMore]);

  const fetchData = async (page) => {
    if (loading) return;
    setLoading(true);
    try {
      const userid = localStorage.getItem('regid');
      const response = await fetch(`${API_BASE_URL}/cpanel/savedBookmark?userid=${userid}&page=${page}`);
      const result = await response.json();

      if (result.length === 0) {
        setHasMore(false);
      } else {
        const uniqueData = [...new Set([...data, ...result])];
        setData(uniqueData);
      //  notify('success', 'Data fetched successfully'); // Notify success
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    //  notify('error', 'Error fetching data'); // Notify error
    } finally {
      setLoading(false);
    }
  };

  const handleUnsave = async (id) => {
    try {
      const userid = localStorage.getItem('regid');
      if (!userid) {
        alert("You are not authorized to access the saved scheme. Please re-login to access the saved scheme.");
        return false;
      }
      const response = await fetch(`${API_BASE_URL}/cpanel/unbookmark`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ schemeId: id, userId: userid }),
      });

      if (response.ok) {
        setData(prevData => prevData.filter(scheme => scheme.sid !== id));
        notify('success', 'Scheme removed successfully'); // Notify success
      } else {
        console.error('Failed to unbookmark scheme');
        notify('error', 'Failed to unbookmark scheme'); // Notify error
      }
    } catch (error) {
      console.error('Error unbookmarking scheme:', error);
      notify('error', 'Error unbookmarking scheme'); // Notify error
    }
  };

  const filteredData = data.filter(scheme => {
    const searchValue = searchTerm.toLowerCase();
    return filterBy === 'scheme_name'
      ? scheme.scheme_name.toLowerCase().includes(searchValue)
      : scheme.state.toLowerCase().includes(searchValue);
  });

  const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return text.substring(0, length) + '...';
  };
  const renderStates = (states) => {
    const stateList = states.split(','); // Assuming states are stored as comma-separated string
    if (stateList.length > 2) {
        return 'All State';
    } else {
        return states;
    }
  };
  const stripHtmlTags = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };
  
  const truncateTexttext = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  };
  
  return (
    <>
      <Settingpop />
      <NotificationExample />
      <div>
        <div className="header-area" id="headerArea">
          <div className="container">
            <div className="header-content position-relative d-flex align-items-center justify-content-between">
              <div className="back-button">
                <Link to="/Home">
                  <i className="bi bi-arrow-left-short"></i>
                </Link>
              </div>
              <div className="page-heading">
                <h2 className="mb-0">Saved Schemes</h2>
              </div>
              <div className="setting-wrapper">
                <div className="setting-trigger-btn" id="settingTriggerBtn">
                <Link to="/Language">
                  <i className="bi bi-gear"></i>
                  </Link>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content-wrapper py-3">
          <div className="container">
           
              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-3 position-relative">
                <input
                  type="text"
                  className="form-control px-5"
                  placeholder="Search by scheme name"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <i className="bi bi-search position-absolute top-50 start-0 translate-middle-y ms-3"></i>
                {searchTerm && (
                  <i
                    className="bi bi-x-circle-fill position-absolute top-50 end-0 translate-middle-y me-3"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setSearchTerm('')}
                  ></i>
                )}
              </div>
              {loading && <LoadingN />}
              <div className="row g-3 justify-content-center">
              {filteredData.map((scheme, index) => (
                <div key={index} className="col-12 col-md-8 col-lg-7 col-xl-6">
                  <div className="card shadow-sm blog-list-card">
                    <div className="card-blog-content">
                      <span className="badge bg-success rounded-pill mb-2 d-inline-block">{renderStates(scheme.state)}</span> 
                    
                      <i
                       className="bi bi-bookmark-x-fill"
                        style={iconStylesIcon}
                        onClick={() => handleUnsave(scheme.sid)}
                      ></i>
                      
                      <a
                        className="blog-title d-block text-dark"
                        onClick={() => navigate('/yojanadetail', {
                          state: {
                            eligibility: scheme.eligibility,
                            exclusions: scheme.exclusions,
                            application_process: scheme.application_process,
                            documents_required: scheme.documents_required,
                            description: scheme.scheme_name,
                            details: scheme.details,
                            statelocation: scheme.state,
                            benefits: scheme.benefits,
                            page:'Bookmark'
                          },
                        })}
                      >
                        {scheme.scheme_name}
                      </a>
                      <div>   {truncateTexttext(stripHtmlTags(scheme.details), 100)}</div>
                      <span className="badge rounded-pill text-bg-light" style={{color:'gray'}}>{scheme.allschemes}</span>
                      <a
                        className="float-end"
                        onClick={() => navigate('/yojanadetail', {
                          state: {
                            eligibility: scheme.eligibility,
                            exclusions: scheme.exclusions,
                            application_process: scheme.application_process,
                            documents_required: scheme.documents_required,
                            description: scheme.scheme_name,
                            details: scheme.details,
                            statelocation: scheme.state,
                            benefits: scheme.benefits,
                            page:'Bookmark'
                          },
                        })}
                      >
                        <i className="bi bi-arrow-right-circle-fill" style={iconStyles}></i>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
               
               {loading && <LoadingN />}
              {!hasMore && <div className="text-center">No more schemes to load</div>} 
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </>
  );
};

const iconStyles = {
  color: 'green',
  fontSize: '24px',
  cursor:'pointer'
};

const iconStylesIcon = {
  color: 'gray', // Change color to indicate "unsaved"
  fontSize: '24px',
  cursor: 'pointer',
  float: 'right'
};

export default Bookmark;
