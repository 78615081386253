// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('mobile');
   if (token) {
      setIsAuthenticated(true);
    }
     setLoading(false);
  }, []);

  const login = (userData) => {
    setLoading(true);
    localStorage.setItem('mobile', userData.mobile);
    localStorage.setItem('lcode', userData.lcode);
    localStorage.setItem('firstname', userData.firstname);
    localStorage.setItem('lastname', userData.lastname);
    localStorage.setItem('selectGender', userData.selectGender);
    localStorage.setItem('selectBusiness', userData.selectBusiness);
    localStorage.setItem('selectedDate', userData.selectedDate);
    localStorage.setItem('age', userData.age);
    localStorage.setItem('userId', userData.user_id);
    localStorage.setItem('regid', userData.reg_id);
    localStorage.setItem('welcomeToastShown', 'true');
    setIsAuthenticated(true);
    setLoading(false);
  };

  const logout = () => {
    setLoading(true);
    localStorage.removeItem('lcode');
    localStorage.removeItem('mobile');
    localStorage.removeItem('firstname');
    localStorage.removeItem('lastname');
    localStorage.removeItem('selectGender');
    localStorage.removeItem('selectBusiness');
    localStorage.removeItem('selectedDate');
    localStorage.removeItem('age');
    localStorage.removeItem('welcomeToastShown','false');
    localStorage.removeItem('userId');
    localStorage.removeItem('regid');
    localStorage.removeItem('pincode');
    localStorage.clear();
    setIsAuthenticated(false);
    setLoading(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
