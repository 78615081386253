import React, { useEffect, useState } from 'react';
import Header from "./Header";
import Sidemenu from "./Sidemenu";
import HomeFooter from "./HomeFooter";
import Homeslider from "./Homeslider";
import Testimonialslider from "./Testimonialslider";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { tns } from 'tiny-slider/src/tiny-slider.js';
import 'tiny-slider/src/tiny-slider.scss';
import { Tooltip, Toast } from 'bootstrap'
import { useLocation, useNavigate ,Link} from "react-router-dom";
import { useGlobalState } from './GlobalStateProvider';
import { API_BASE_URL } from './Config';

import logo from './images/3.png';  
import profilePic from './images/user1.png';  
import qrCode from './images/qr.png';  
import background from './images/55.png';  
const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentPage,sstate,ccity,ppincode } = useGlobalState();
  const [showPreloader, setShowPreloader] = useState(true);
  const [alertText, setAlertText] = useState('');
  const [alertColor, setAlertColor] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showToast, setShowToast] = useState(false); 
  const [checkprofile, setCheckprofile] = useState(0); 
  const [cardnumber, setCardnumber] = useState(null); 
  const [firstname, setFirstname] = useState(0); 
  const [lastname, setLastname] = useState(0); 
  const [gender, setGender] = useState(0); 
  const [dob, setDob] = useState(0); 
  const [photo,setPhoto]=useState(profilePic);
  const [barcode,setBarcode]=useState('');
  const [englishText, setEnglishText] = useState('');
  const [hindiText, setHindiText] = useState('');
  const [checkpincode, setCheckpincode] = useState('');
  const [rastion, setRastion] = useState('');
  const handleEdit = async(id) => {
  
    const switchProfileUrl = `${API_BASE_URL}/cpanel/checkcompeletprofile?userid=${id}`;
  try {
      const response = await fetch(switchProfileUrl, {
        method: 'GET'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
       const responseData = await response.json();
       setCheckprofile(responseData.completed);
      
       setCardnumber(responseData.cardnumber);
       setPhoto(responseData.photo);
       setBarcode(responseData.qrcode);
       setCheckpincode(responseData.pincode);
       setRastion(responseData.ration);
       localStorage.setItem('profilephoto',responseData.photo);
 } catch (error) {
      console.error(`Error switching profile: ${error}`);
    } 
 
  };

  const handleKeyPress = async (englishText) => {
   
      const response = await fetch(`https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=hi&dt=t&q=${encodeURI(englishText)}`);
      const result = await response.json();
      if (result[0] !== undefined && result[0] !== null) {
        setHindiText(result[0][0][0]);
      } else {
        setHindiText('------');
      }
    
  };


  useEffect(() => {
   
 
  
    const firstname1=localStorage.getItem('firstname');
    const lastname1=localStorage.getItem('lastname');
    const selectGender1=localStorage.getItem('selectGender');
    const selectedDate1=localStorage.getItem('selectedDate');
    handleKeyPress(firstname1+' '+lastname1);
    setFirstname(firstname1);
    setLastname(lastname1);
    setGender(selectGender1);
    setDob(selectedDate1);
   const rgid=localStorage.getItem('regid');
   handleEdit(rgid);
    // Initialize tooltips
    const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltips.forEach(tooltip => new Tooltip(tooltip));

    // Initialize toasts
    const toasts = document.querySelectorAll('.toast');
    toasts.forEach(toast => new Toast(toast).show());

    // Add toast showing function
    const toastBtn = document.getElementById('toast-showing-btn');
    if (toastBtn) {
      toastBtn.addEventListener('click', () => {
        const affanToasts = document.querySelectorAll('.toast');
        affanToasts.forEach(toast => new Toast(toast).show());
      });
    }

    // Toast Animation
    const toastAnimation = () => {
      const autohideToasts = document.querySelectorAll('.toast-autohide');
      autohideToasts.forEach(toast => {
        toast.insertAdjacentHTML('beforeend', '<span class="toast-autohide-animation"></span>');
        const toastDuration = toast.getAttribute('data-bs-delay');
        const toastAnimDuration = toastDuration + 'ms';
        const autohideAnimations = document.querySelectorAll('.toast-autohide-animation');
        autohideAnimations.forEach(animation => animation.style.animationDuration = toastAnimDuration);
      });
    };

    window.addEventListener('load', toastAnimation); // Run toastAnimation when window loads

  }, [location.search]); // Run only once after component mount

  const handleOfflineClick = () => {
    setAlertText('Oops! No internet connection.');
    setAlertColor('#ea4c62');
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };
  const featuresone = [
    { img: 'img/demo-img/eligibleschemes.png', text: 'Qualified Schemes', path: '/Qualifiedscheme' },
    { img: 'img/demo-img/savedschemes.png', text: 'Saved Schemes', path: '/Bookmark' },
    { img: 'img/demo-img/msmeschemes.png', text: 'MSME Schemes', path: '/msme-schemes' }
  ];
  const featurestwo = [
    { img: 'img/demo-img/addprofile.png', text: 'Create a Profile', path: '/ChildProfile' },
    { img: 'img/demo-img/switchprofile.png', text: 'Change Profile', path: '/Changeprofile' },
    { img: 'img/demo-img/mybooking.png', text: 'My Bookings', path: '/Mybooking' }
  ];
  const handleOnlineClick = () => {
    setAlertText('Your internet connection is back.');
    setAlertColor('#00b894');
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  return (
    <>
      <Header />
      <Sidemenu />
      <div className="page-content-wrapper">
        {currentPage==null?  <></>
        :
        <div className="container">
             <div className="pt-3"></div>
            <div className="card-body direction-rtl p-4">
            <h2 className="text-gray"> Supervisor! </h2>
            <Link to={`/Currentpagelogout?geturl=${currentPage}&state=${sstate}&city=${ccity}&pincode=${ppincode}`} className="btn btn-warning mb-3">Go to the dashboard</Link>
      </div>
        </div>
      }
      
      
       {cardnumber !== null && cardnumber !== ""?
         <div className="container card card_bg mb-3" style={{alignItems:'center', alignItems: 'center',
     }}>
            <div className="pt-3"></div>
        <div 
     
      style={{
        backgroundImage: `url(${background})`,
       
      
        backgroundSize: 'contain',
        display: 'flex',
        justifyContent: 'space-between',
        width:'100%',
        maxWidth:'365px',
        backgroundRepeat: 'no-repeat',
        border: '2px solid red'
      
      }}
    >
      <div className="header" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="logo" style={{ marginTop: '20px' }}></div>
        <div className="scheme-name"></div>
      </div>
      <div class="container">
  <div class="row">
    <div class="col"  style={{marginTop:'50px',width:'100%',maxWidth:'110px',alignItems:'center'}}>
    {photo ? (
  <img
    src={photo}
    style={{
      width: '83px',
      marginLeft: '-6px',
      borderRadius: '44px',
      marginTop: '7px',
      marginLeft: '8px'
    }}
    alt="User Photo"
  />
) : (
  <img
  src={profilePic}
  style={{
    width: '83px',
    marginLeft: '-6px',
    borderRadius: '44px',
    marginTop: '7px',
    marginLeft: '8px'
  }}
  alt="User Photo"
/>
)}
          
          <br/>
             <strong style={{fontSize:'1.5vh',marginTop:'10px',float:'right'}}>{cardnumber}</strong>
    </div>
   
    <div class="col" style={{marginTop:'18.2%'}}>
           <strong style={{fontSize:'1.5vh'}}>{hindiText} / {firstname} {lastname}</strong><br/>
           <strong style={{fontSize:'1.5vh'}}>जन्म तिथि /{dob}</strong><br/>
           <strong style={{fontSize:'1.5vh'}}>लिंग /  {gender}</strong>
           <img
            src={barcode}
            alt={firstname}
            style={{ width: '64px',float:'right' ,marginBottom:'21%',padding:'6px' }}
         
          />
    </div>
    {/* <div class="col "  style={{marginTop:'140px',marginBottom:'40px'}}>
   
    </div> */}
  </div>
</div>
      
    </div>
    <div className="pt-3"></div>
       </div>
       
      :
      <>
      <Homeslider />
      <div className="pt-3"></div>
      <div className="container">
    {rastion==null?
      <div className="card card-bg-img bg-img bg-overlay mb-3" >
        <div className="card-body direction-rtl p-4">
          <h2 className="text-white">Your profile is incomplete</h2>
          <p className="mb-4 text-white">Complete Profile to check eligibility</p>
         {checkpincode!=null?
          <Link to="/Completeprofile" className="btn btn-warning" >Complete your profile</Link>
          :
          <Link to="/Address" className="btn btn-warning" >Complete your profile</Link>
          }
        </div>
      </div>
      :
      <></>

        }
    </div>
    </>    
      }
       
      
        <div className="container direction-rtl">
      <div className="card mb-3">
        <div className="card-body">
          <div className="row g-3">
            {featuresone.map((feature, index) => (
              <div className="col-4" key={index}>
                <Link to={feature.path} className="feature-card mx-auto text-center">
                  <div className="card mx-auto bg-gray">
                    <img src={feature.img} alt={feature.text} />
                  </div>
                  <p className="mb-0">{feature.text}</p>
                </Link>
              </div>
            ))}
            
          </div>
        </div>
      </div>
    </div>

    <div className="container direction-rtl">
      <div className="card mb-3">
        <div className="card-body">
          <div className="row g-3">
            {featurestwo.map((feature, index) => (
              <div className="col-4" key={index}>
                <Link to={feature.path} className="feature-card mx-auto text-center">
                  <div className="card mx-auto bg-gray">
                    <img src={feature.img} alt={feature.text} />
                  </div>
                  <p className="mb-0">{feature.text}</p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>

        <div className="container">
          <div className="card bg-primary mb-3 bg-img" style={{ backgroundImage: `url('img/bg-img/yojanacard.jpg')` }}>
            <div className="card-body direction-rtl p-4 " style={{ height: '90px' }}>
              {/* <h2 className="text-white">Ready pages</h2>
              <p className="mb-4 text-white">Already designed more than 100+ pages for your needs. Such as - Authentication,
                Chats, eCommerce, Blog &amp; Miscellaneous pages.</p>
              <a className="btn btn-warning" href="pages.html">All Pages</a> */}
              
            </div>
          </div>
        </div>

        <Testimonialslider />

        <div className="pb-3"></div>
      </div>
      <HomeFooter />
      {showAlert && (
        <div id="internetStatus" style={{ display: 'block', backgroundColor: alertColor }}>
          {alertText}
        </div>
      )}
    </>
  );
};

export default Home;




