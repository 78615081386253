

import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { tns } from 'tiny-slider/src/tiny-slider.js';
import 'tiny-slider/src/tiny-slider.scss';

const Homeslider = () => {
  useEffect(() => {
   
    
      const slider = tns({
        container: '.tiny-slider-one',
        items: 1,
        slideBy: 'page',
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 5000,
        speed: 1000,
        mouseDrag: true,
        controlsText: [('<i class="bi bi-chevron-left"></i>'), ('<i class="bi bi-chevron-right"></i>')]
      });

      // Cleanup slider on component unmount
     

      // Cleanup slider on component unmount
      return () => {
        slider.destroy();

      };
    
    
    // Initialize the slider after the component is mounted
    
    

  }, []);


  return (
 <div className='container '>
  <div className="tiny-slider-one-wrapper mt-5">
      <div className="tiny-slider-one">
        {/* Single Hero Slide */}
        {[
          {
            img: 'img/bg-img/31.jpg',
            title: 'Build with Bootstrap 5',
            text: 'Build fast, responsive sites with Bootstrap.',
            btnText: 'Buy Now',
            btnLink: '#'
          },
          {
            img: 'img/bg-img/33.jpg',
            title: 'Vanilla JavaScript',
            text: 'The whole code is written with vanilla JS.',
            btnText: 'Buy Now',
            btnLink: '#'
          },
          {
            img: 'img/bg-img/32.jpg',
            title: 'PWA Ready',
            text: 'Click the "Add to Home Screen" button &amp; enjoy it like an app.',
            btnText: 'Buy Now',
            btnLink: '#'
          },
          {
            img: 'img/bg-img/33.jpg',
            title: 'Lots of Elements &amp; Pages',
            text: 'Create your website in days, not months.',
            btnText: 'Buy Now',
            btnLink: '#'
          },
          {
            img: 'img/bg-img/1.jpg',
            title: 'Dark &amp; RTL Ready',
            text: 'You can use the Dark or RTL mode of your choice.',
            btnText: 'Buy Now',
            btnLink: '#'
          }
        ].map((slide, index) => (
          <div key={index}>
            <div className="single-hero-slide" style={{ backgroundImage: `url(${slide.img})` }}>
              <div className="h-100 d-flex align-items-center text-center">
                {/* <div className="container">
                  <h3 className="text-white mb-1">{slide.title}</h3>
                  <p className="text-white mb-4" dangerouslySetInnerHTML={{ __html: slide.text }}></p>
                  <a className="btn btn-creative btn-warning" href={slide.btnLink}>{slide.btnText}</a>
                </div> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
  );
};

export default Homeslider; 
