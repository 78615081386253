import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const Logout = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    navigate('/Login'); // Ensure this path matches your login route
  }, [logout, navigate]);

  // Return null to render nothing
  return null;
};

export default Logout;



