import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import Settingpop from './Settingpop';
import HomeFooter from "./HomeFooter";
import GoogleTranslate from './GoogleTranslate';

const Language = () => {
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const includedLanguages = 'en,hi';

  useEffect(() => {
    const savedLanguage = localStorage.getItem('lag') || 'en';
    setSelectedLanguage(savedLanguage);
  }, []);

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.id;
    setSelectedLanguage(newLanguage);
    localStorage.setItem('lag', newLanguage);
  };

  return (
    <>
      <Settingpop />
      <div>
        <div className="header-area" id="headerArea">
          <div className="container">
            <div className="header-content position-relative d-flex align-items-center justify-content-between">
              <div className="back-button">
                <Link to="/Home">
                  <i className="bi bi-arrow-left-short"></i>
                </Link>
              </div>
              <div className="page-heading">
                <h6 className="mb-0">Select Language</h6>
              </div>
              <div className="setting-wrapper">
                <div className="setting-trigger-btn" id="settingTriggerBtn">
                  <i className="bi bi-gear"></i>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content-wrapper py-3">
          <div className="container">
            <div className="card">
              <div className="card-body">
                <div className="language-area-wrapper">
                  <ul className="ps-0 language-lists">
                    <li>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="languageRadio"
                          id="en"
                          checked={selectedLanguage === 'en'}
                          onChange={handleLanguageChange}
                        />
                        <label className="form-check-label" htmlFor="en">
                          English
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="languageRadio"
                          id="hi"
                          checked={selectedLanguage === 'hi'}
                          onChange={handleLanguageChange}
                        />
                        <label className="form-check-label" htmlFor="hi">
                          Hindi
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div id="google_translate_element"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
      <GoogleTranslate pageLanguage={selectedLanguage} includedLanguages={includedLanguages} />
    </>
  );
};

export default Language;
