import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from './AuthContext';

// const ProtectedRoute = () => {
  
//   const token = localStorage.getItem('mobile');
//   const { isAuthenticated } = useContext(AuthContext);
//    return token ? <Outlet /> : <Navigate to="/login" />;
// };
// export default ProtectedRoute;

const ProtectedRoute = () => {
  const { isAuthenticated, loading } = useContext(AuthContext);
  const token = localStorage.getItem('mobile');
  if (loading) {
    return <div>Loading...</div>;
  }
// alert('test');
// return false;
  return token ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
