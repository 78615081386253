

import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { tns } from 'tiny-slider/src/tiny-slider.js';
import 'tiny-slider/src/tiny-slider.scss';

const Header = () => {
 

  return (
  
    <div className="header-area" id="headerArea">
      <div className="container">
        {/* Header Content */}
        <div className="header-content header-style-five position-relative d-flex align-items-center justify-content-between">
          {/* Logo Wrapper */}
          <div className="logo-wrapper">
            <a href="home.html">
              <img src="img/core-img/logo.png" alt="Logo" />
            </a>
          </div>

          {/* Navbar Toggler */}
          <div 
            className="navbar--toggler" 
            id="affanNavbarToggler" 
            data-bs-toggle="offcanvas" 
            data-bs-target="#affanOffcanvas" 
            aria-controls="affanOffcanvas"
          >
            <span className="d-block"></span>
            <span className="d-block"></span>
            <span className="d-block"></span>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Header;
