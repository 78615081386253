

import React, { useEffect ,useRef,useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { tns } from 'tiny-slider/src/tiny-slider.js';
import 'tiny-slider/src/tiny-slider.scss';
import { Link } from 'react-router-dom';

const  Sidemenu = () => {
  const [firstname, setFrstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [mobile, setMobile] = useState('');
  const [photo, setPhoto] = useState('');
  useEffect(() => {
            const firstname=localStorage.getItem('firstname');
            const lastname=localStorage.getItem('lastname');
            const selectGender=localStorage.getItem('selectGender');
            const selectBusiness=localStorage.getItem('selectBusiness');
            const selectedDate= localStorage.getItem('selectedDate');
            const age=localStorage.getItem('age');
            const mobile=localStorage.getItem('mobile');
           const photo=localStorage.getItem('profilephoto');
            setFrstname(firstname);
            setLastname(lastname);
            setMobile(mobile);
           if(photo){
            setPhoto(photo);
           }else{
            setPhoto('https://cdn-icons-png.flaticon.com/512/3135/3135715.png');
           }
         
            
  }, [setPhoto]);


  return (

    <div
      className="offcanvas offcanvas-start"
      id="affanOffcanvas"
      data-bs-scroll="true"
      tabIndex="-1"
      aria-labelledby="affanOffcanvsLabel"
    >
      <button
        className="btn-close btn-close-white text-reset"
        type="button"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
      <div className="offcanvas-body p-0">
        <div className="sidenav-wrapper">
          {/* Sidenav Profile */}
          <div className="sidenav-profile bg-gradient">
            <div className="sidenav-style1"></div>
            {/* User Thumbnail */}
            <div className="user-profile">
            
             {photo ? (
    <img src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png" alt={firstname} />
              ) : (
                <img src={photo} alt={firstname} />
              )}
            </div>
            {/* User Info */}
            <div className="user-info">
              <h6 className="user-name mb-0">{firstname} {lastname}</h6>
              <span>{mobile}</span>
            </div>
          </div>
          {/* Sidenav Nav */}
          <ul className="sidenav-nav ps-0">
            <li>
              <Link to="/Profile">
                <i className="bi bi-person"></i> Create a Profile
              </Link>
            </li>
            <li>
              <Link to="/Qualifiedscheme">
                <i className="bi bi-card-checklist"></i> Qualified Schemes
                {/* <span className="badge bg-danger rounded-pill ms-2">220+</span> */}
              </Link>
            </li>
            <li>
              <Link to="/Bookmark">
                <i className="bi bi-bookmark-check"></i> Stored Schemes
                {/* <span className="badge bg-success rounded-pill ms-2">100+</span> */}
              </Link>
            </li>
            <li>
              <Link to="/Mybooking">
                <i className="bi bi-bookmark"></i> Reserved Bookings
                {/* <span className="badge bg-success rounded-pill ms-2">100+</span> */}
             
              </Link>
            </li>
            <li>
              <Link to="/Changeprofile">
                <i className="bi bi-arrow-repeat"></i> Change Profile
              </Link>
              {/* <ul>
                <li>
                  <Link to="/Language">App Setting</Link>
                </li>
                <li>
                  <Link to="/shop-list"> Forget me</Link>
                </li>
                <li>
                  <Link to="/shop-details"> Logout</Link>
                </li>
                <li>
                  <Link to="/cart"> Cart</Link>
                </li>
                <li>
                  <Link to="/checkout"> Checkout</Link>
                </li>
              </ul> */}
            </li>
            <li>
              <Link to="/Language">
                <i className="bi bi-gear"></i> App Setting
              </Link>
            </li>
            {/* <li>
              <div className="night-mode-nav">
                <i className="bi bi-moon"></i> Night Mode
                <div className="form-check form-switch">
                  <input
                    className="form-check-input form-check-success"
                    id="darkSwitch"
                    type="checkbox"
                  />
                </div>
              </div>
            </li> */}
            <li>
              <Link to="/Logout">
                <i className="bi bi-box-arrow-right"></i> Logout
              </Link>
            </li>
          </ul>
          {/* Social Info */}
          {/* <div className="social-info-wrap">
            <a href="#">
              <i className="bi bi-facebook"></i>
            </a>
            <a href="#">
              <i className="bi bi-twitter"></i>
            </a>
            <a href="#">
              <i className="bi bi-linkedin"></i>
            </a>
          </div> */}
          {/* Copyright Info */}
          <div className="copyright-info">
            <p>
              <span id="copyrightYear"></span> &copy; Made by{' '}
              <a href="#">App ki Yojana</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidemenu;
