import React, { createContext, useState, useEffect, useContext } from 'react';

// Create the context
const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState('');
  const [sstate, setState] = useState('');
  const [ccity, setCity] = useState('');
  const [ppincode, setPincode] = useState('');
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const currentPageParam = urlParams.get('currentPage');
    const state = urlParams.get('state');
    const city = urlParams.get('city');
    const pincode = urlParams.get('pincode');
    setCurrentPage(currentPageParam);
    setState(state);
    setCity(city);
    setPincode(pincode);
  }, []);

  return (
    <GlobalStateContext.Provider value={{ currentPage, setCurrentPage, sstate, setState,ccity, setCity,ppincode, setPincode}}>
      {children}
    </GlobalStateContext.Provider>
  );
};

// Custom hook to use the GlobalStateContext
export const useGlobalState = () => useContext(GlobalStateContext);
