
import React, { useEffect,useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';

import { BrowserRouter, Routes, Route ,Router} from "react-router-dom";
const Getstarted = () => {
 

  return (
   <>
  
  
  <div className="hero-block-wrapper bg-primary">
      {/* Styles */}
      <div className="hero-block-styles">
        <div className="hb-styles1" style={{ backgroundImage: "url('img/core-img/dot.png')" }}></div>
        <div className="hb-styles2"></div>
        <div className="hb-styles3"></div>
      </div>

      <div className="custom-container">
        {/* Skip Page */}
        <div className="skip-page">
          <Link to="/home">Skip</Link>
        </div>

        {/* Hero Block Content */}
        <div className="hero-block-content">
          <img className="mb-4" src="img/bg-img/19.png" alt="" />
          <h2 className="display-4 text-white mb-3">Learn how to apply and get support on your application</h2>
          <p className="text-white">Save your schemes and track your bookings.</p>
          <Link className="btn btn-warning btn-lg w-100" to={{ pathname: "/Home", state: { welcomeMessage: "welcome" } }}>Get Started</Link>
        </div>
      </div>
    </div>
  
   </>
  );
};

export default Getstarted;
