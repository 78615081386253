
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from "react";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Otp from "./pages/Otp";
import Qrcode from "./pages/Qrcode";
import NoPage from "./pages/NoPage";
import Yojanalist from "./pages/Yojanalist";
import Yojanadetail from "./pages/Yojanadetail";
import Bookmark from "./pages/Bookmark";
import Otpconfirm from "./pages/Otpconfirm";
import Language from "./pages/Language";
import Getstarted from "./pages/Getstarted";
import Global from './pages/Global';
import Registration from './pages/Registration';
import Profile from './pages/Profile';
import Logout from './pages/Logout';
import ProtectedRoute from './pages/ProtectedRoute';
import ProfileInfo from './pages/ProfileInfo';
import Changeprofile from './pages/Changeprofile';
import ChildProfile from './pages/ChildProfile';
import Mybooking from './pages/Mybooking';
import Qualifiedscheme from './pages/Qualifiedscheme';
import Completeprofile from './pages/Completeprofile';
import Msmescheme from './pages/Msmescheme';
import Congratulation from './pages/Congratulation';
import Address from './pages/Address';
import Currentpagelogout from './pages/Currentpagelogout';
import { AuthProvider, AuthContext } from './pages/AuthContext';
import Loading from './pages/Loader';
import ErrorBoundary from './pages/ErrorBoundary';
import Schema from './pages/Schema';
import Supervisorlist from './pages/Supervisorlist';

const App = () => {
  return (
    <AuthProvider>
      <Router>
      <ErrorBoundary>
        <Global/>
        
        <AuthContext.Consumer>
          {({ loading }) => (
            <>
              {loading && <Loading loading={loading} />}
              <Routes>
                
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/otp" element={<Otp />} />
                <Route path="/otpconfirm" element={<Otpconfirm />} />
                <Route path="/qrcode" element={<Qrcode />} />
                <Route path="/Registration" element={<Registration />} />
            
               <Route path="/" element={<ProtectedRoute />}>
              <Route  path="/" element={<Getstarted />} />
              <Route path="/home" element={<Home />} />
              <Route path="/yojanalist" element={<Yojanalist />} />
              <Route path="/Bookmark" element={<Bookmark />} />
             <Route path="/yojanadetail" element={<Yojanadetail />} />
             <Route path="/language" element={<Language />} />
             <Route path="/profile" element={<Profile />} />
             <Route path="/Changeprofile" element={<Changeprofile/>} />
             <Route path="/ChildProfile" element={<ChildProfile/>} />
             <Route path="/Mybooking" element={<Mybooking/>} />
             <Route path="/Qualifiedscheme" element={<Qualifiedscheme/>} />
             <Route path="/Completeprofile" element={<Completeprofile/>} />
             <Route path="/Congratulation" element={<Congratulation/>} />
             <Route path="/ProfileInfo" element={<ProfileInfo />} />
             <Route path="/Msmescheme" element={<Msmescheme />} />
             <Route path="/Supervisorlist" element={<Supervisorlist />} />
             <Route path="/Schema" element={<Schema />} />
             <Route path="/Address" element={<Address />} />
             <Route path="/Logout" element={<Logout />} />
             <Route path="/Currentpagelogout" element={<Currentpagelogout />} />
             </Route>
             
             
        <Route path="*" element={<NoPage />} />
       </Routes>
            </>
          )}
        </AuthContext.Consumer>
        </ErrorBoundary>
      </Router>
    </AuthProvider>
  );
};

export default App;
