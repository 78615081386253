import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import { API_BASE_URL } from './Config';

const ChildProfile = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      termsAccepted: false,
      selectGender: '',
      selectBusiness: '',
      selectedDate: '',
      age: '',
      mobile: '',
      relation: '',
      relationType: '',
      maritalstatus: '',  // New field for marital status
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required('Enter first name.'),
      lastname: Yup.string().required('Enter last name'),
      termsAccepted: Yup.bool().oneOf([true], 'You must accept the terms and conditions'),
      selectGender: Yup.string().required('Select gender'),
      selectBusiness: Yup.string().required('Select business type'),
      selectedDate: Yup.date().required('Enter your date of birth.'),
      age: Yup.number().required('').min(1, 'Age must be greater than 1'),
      relation: Yup.string().required('Select relation'),
      relationType: Yup.string()
        .when(['relation'], {
          is: (relation) => relation === 'Family',
          then: (schema) => schema.required('Select relation type')
        }),
      maritalstatus: Yup.string().required('Select marital status'),
    }),
    onSubmit: async (values) => {
      setLoading(true);

      const formattedValues = {
        ...values,
        firstname: formatText(values.firstname),
        lastname: formatText(values.lastname),
      };

      try {
        const response = await axios.post(API_BASE_URL + 'cpanel/childregistration', formattedValues);
        if (response.data.success) {
          if (values.maritalstatus === 'Married') {
            navigate('/supervisorlist');
          } else {
            navigate('/Changeprofile');
          }
        } else {
          alert("Registration submission failed. Please try again.");
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setLoading(false);
      }
    },
  });

  const formatText = (text) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const calculateBirthDate = (age) => {
    const today = new Date();
    const birthYear = today.getFullYear() - age;
    let birthDate = new Date(today.setFullYear(birthYear));
    return birthDate.toISOString().split('T')[0];
  };

  useEffect(() => {
    const phone = localStorage.getItem('mobile');
    const pincode = localStorage.getItem('pincode');
    if (pincode == null || pincode === "") {
      setShowModal(true);
      return;
    }
    setPhoneNumber(phone);
    formik.setFieldValue('mobile', phone);

    if (formik.values.age) {
      const birthDate = calculateBirthDate(formik.values.age);
      formik.setFieldValue('selectedDate', birthDate);
    }
  }, [formik.values.age]);

  useEffect(() => {
    if (formik.values.selectedDate) {
      const birthDate = new Date(formik.values.selectedDate);
      const age = calculateAge(birthDate);
      formik.setFieldValue('age', age);
    }
  }, [formik.values.selectedDate]);

  const handleNext = () => {
    setShowModal(false);
    navigate('/');
  };

  return (
    <>
      <div className="login-back-button">
        <Link to="/Changeprofile">
          <i className="bi bi-arrow-left-short"></i>
        </Link>
      </div>
      <div className="login-wrapper d-flex justify-content-center">
        <div className="custom-container">
          <div className="register-form">
            <h2 className="mb-5 text-center">Add a Profile</h2>
            <form onSubmit={formik.handleSubmit}>
              <div className="d-flex align-items-center">
                <div className="form-group me-3">
                  <label className="form-label">First Name</label>
                  <input
                    className="form-control"
                    id="firstname"
                    type="text"
                    {...formik.getFieldProps('firstname')}
                    value={formatText(formik.values.firstname)}
                  />
                  {formik.touched.firstname && formik.errors.firstname ? (
                    <div className="text-danger">{formik.errors.firstname}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label className="form-label">Last Name</label>
                  <input
                    className="form-control"
                    id="lastname"
                    type="text"
                    {...formik.getFieldProps('lastname')}
                    value={formatText(formik.values.lastname)}
                  />
                  {formik.touched.lastname && formik.errors.lastname ? (
                    <div className="text-danger">{formik.errors.lastname}</div>
                  ) : null}
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">Gender</label>
                <select
                  className="form-select form-control-clicked"
                  id="selectGender"
                  {...formik.getFieldProps('selectGender')}
                >
                  <option value="">--Select--</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                {formik.touched.selectGender && formik.errors.selectGender ? (
                  <div className="text-danger">{formik.errors.selectGender}</div>
                ) : null}
              </div>
              <div className="form-group">
                <label className="form-label">Are you a business person?</label>
                <select
                  className="form-select form-control-clicked"
                  id="selectBusiness"
                  {...formik.getFieldProps('selectBusiness')}
                >
                  <option value="">--Select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                {formik.touched.selectBusiness && formik.errors.selectBusiness ? (
                  <div className="text-danger">{formik.errors.selectBusiness}</div>
                ) : null}
              </div>
              <div className="d-flex align-items-center">
                <div className="form-group me-1">
                  <label className="form-label" htmlFor="inputDate">Date of Birth</label>
                  <input
                    className="form-control form-control-clicked"
                    id="inputDate"
                    type="date"
                    {...formik.getFieldProps('selectedDate')}
                  />
                  {formik.touched.selectedDate && formik.errors.selectedDate ? (
                    <div className="text-danger">{formik.errors.selectedDate}</div>
                  ) : null}
                </div>
                <span className="mx-2">or</span>
                <div className="form-group text-start mb-3">
                  <label className="form-label" htmlFor="inputAge">Age (read only)</label>
                  <input
                    className="form-control"
                    type="text"
                    id="inputAge"
                    readOnly={true}
                    {...formik.getFieldProps('age')}
                  />
                  {formik.touched.age && formik.errors.age ? (
                    <div className="text-danger">{formik.errors.age}</div>
                  ) : null}
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">Relation</label>
                <select
                  className="form-select form-control-clicked"
                  id="relation"
                  {...formik.getFieldProps('relation')}
                >
                  <option value="">--Select--</option>
                  <option value="Family">Family</option>
                </select>
                {formik.touched.relation && formik.errors.relation ? (
                  <div className="text-danger">{formik.errors.relation}</div>
                ) : null}
              </div>
              {formik.values.relation === 'Family' && (
                <div className="form-group">
                  <label className="form-label">Relation Type</label>
                  <select
                    className="form-select form-control-clicked"
                    id="relationType"
                    {...formik.getFieldProps('relationType')}
                  >
                    <option value="">--Select--</option>
                    <option value="Son">Son</option>
                    <option value="Daughter">Daughter</option>
                    <option value="Wife">Wife</option>
                  </select>
                  {formik.touched.relationType && formik.errors.relationType ? (
                    <div className="text-danger">{formik.errors.relationType}</div>
                  ) : null}
                </div>
              )}
              <div className="form-group">
                <label className="form-label">Marital Status</label>
                <select
                  className="form-select form-control-clicked"
                  id="maritalstatus"
                  {...formik.getFieldProps('maritalstatus')}
                >
                  <option value="">--Select--</option>
                  <option value="Married">Married</option>
                  <option value="Unmarried">Unmarried</option>
                </select>
                {formik.touched.maritalstatus && formik.errors.maritalstatus ? (
                  <div className="text-danger">{formik.errors.maritalstatus}</div>
                ) : null}
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  id="checkedCheckbox"
                  type="checkbox"
                  {...formik.getFieldProps('termsAccepted')}
                />
                <label className="form-check-label text-muted fw-normal" htmlFor="checkedCheckbox">
                  I agree with the terms & policy.
                </label>
                {formik.touched.termsAccepted && formik.errors.termsAccepted ? (
                  <div className="text-danger">{formik.errors.termsAccepted}</div>
                ) : null}
              </div>
              <input
                type="hidden"
                {...formik.getFieldProps('mobile')}
              />
              <Button className="btn btn-primary w-100" type="submit" disabled={loading}>
                {loading ? 'Processing...' : 'Submit'}
              </Button>
            </form>
          </div>
          <div className="login-meta-data text-center">
            <p className="mt-3 mb-0">
              Already have an account? <Link className="stretched-link" to="/Login"> Login</Link>
            </p>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" keyboard={false}>
        <Modal.Header >
          <Modal.Title>Incomplete Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Complete your profile first before you add a beneficiary.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleNext}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChildProfile;
