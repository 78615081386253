import React, { useEffect, useContext, useState } from 'react';
import { AuthContext } from './AuthContext';
import { useLocation } from 'react-router-dom';

const Currentpagelogout = () => {
  const { logout } = useContext(AuthContext);
  const location = useLocation();
  const [parameters, setParameters] = useState({
    interntype: '',
    state: '',
    city: '',
    pincode: ''
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const interntype = searchParams.get('interntype') || '';
    const state = searchParams.get('state') || '';
    const city = searchParams.get('city') || '';
    const pincode = searchParams.get('pincode') || '';

    setParameters({ interntype, state, city, pincode });
   
    const urld = `https://connect.aapkiyojana.org/userlist?login=${interntype}&state=${state}&city=${city}&pincode=${pincode}`;
    https://connect.aapkiyojana.org/
    // Perform logout
    logout();

    // Check if the current window is inside an iframe
    if (window.parent !== window) {
      // Redirect the parent window
      window.parent.location.href = urld;
    } else {
      // If not in an iframe, redirect normally
      window.location.href = urld;
    }
  }, [logout, location]);

  return null; // Since this component doesn't render anything
};

export default Currentpagelogout;
