import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import HomeFooter from "./HomeFooter";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from './Config';
import NotificationExample, { notify } from './NotificationExample';
import LoadingN from './Loader';

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null); // State to hold the selected file

  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    firstname: '',
    lastname: '',
    mobile: '',
    selectGender: '',
    selectBusiness: '',
    selectedDate: '',
    age: '',
  });

  useEffect(() => {
    const titles = [
      'firstname',
      'lastname',
      'mobile',
      'selectGender',
      'selectBusiness',
      'selectedDate',
      'age',
    ];
    const reg_id = localStorage.getItem('regid');
    handleEdit(reg_id);
    titles.forEach((title) => {
      const storedValue = localStorage.getItem(title);
      if (storedValue) {
        setProfileData((prevData) => ({ ...prevData, [title]: storedValue }));
      }
    });
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setProfileData((prevData) => ({ ...prevData, [id]: value }));
    localStorage.setItem(id, value); // Save to localStorage whenever there's a change
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const reg_id = localStorage.getItem('regid');
    const dataToSubmit = { ...profileData, reg_id };

    // Create FormData object to send files and other data
    const formData = new FormData();
    formData.append('file', file); // Append file to FormData

    // Append profile data fields to FormData
    Object.keys(dataToSubmit).forEach((key) => {
      formData.append(key, dataToSubmit[key]);
    });

    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}cpanel/saveProfileData`, {
        method: 'POST',
        body: formData, // Send FormData instead of JSON.stringify(dataToSubmit)
      });
      const result = await response.json();
      if (result.Status) {
        navigate('/Address');
      } else {
        notify('error', result.message);
        navigate('/Address');
      }
    } catch (error) {
      console.error('Error submitting profile data:', error);
    }
  };

  const handleEdit = async (id) => {
    const switchProfileUrl = `${API_BASE_URL}/cpanel/switchprofile?userid=${id}`;

    try {
      const response = await fetch(switchProfileUrl, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();

      // Set profile data to localStorage
      Object.keys(responseData).forEach((key) => {
        localStorage.setItem(key, responseData[key]);
      });
    } catch (error) {
      console.error(`Error switching profile: ${error}`);
    }
  };

  return (
    <>
      <NotificationExample />
      <div>
        <div className="header-area" id="headerArea">
          <div className="container">
            <div className="header-content position-relative d-flex align-items-center justify-content-between">
              <div className="back-button">
                <Link to="/Changeprofile">
                  <i className="bi bi-arrow-left-short"></i>
                </Link>
              </div>
              <div className="page-heading">
                <h2 className="mb-0">Profile</h2>
              </div>
              <div className="setting-wrapper">
                <Link to="/Language">
                  <i className="bi bi-gear"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content-wrapper py-3">
        {/* Element Heading */}
        <div className="container">
          <div className="element-heading">
            <h6>Complete Profile to check eligibility</h6>
          </div>
        </div>
        {loading && <LoadingN />}
        <div className="container">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="form-label" htmlFor="firstname">First Name</label>
                  <input
                    className="form-control"
                    id="firstname"
                    type="text"
                    value={profileData.firstname}
                    onChange={handleChange}
                  
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="lastname">Last Name</label>
                  <input
                    className="form-control"
                    id="lastname"
                    type="text"
                    value={profileData.lastname}
                    onChange={handleChange}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="mobile">Mobile</label>
                  <input
                    className="form-control"
                    id="mobile"
                    type="text"
                    value={profileData.mobile}
                    onChange={handleChange}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="selectGender">Gender</label>
                  <input
                    className="form-control"
                    id="selectGender"
                    type="text"
                    value={profileData.selectGender}
                    onChange={handleChange}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="selectBusiness">Are you business man?</label>
                  <input
                    className="form-control"
                    id="selectBusiness"
                    type="text"
                    value={profileData.selectBusiness}
                    onChange={handleChange}
                    
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="selectedDate">Date</label>
                  <input
                    className="form-control"
                    id="selectedDate"
                    type="date"
                    value={profileData.selectedDate}
                    onChange={handleChange}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="age">Age</label>
                  <input
                    className="form-control"
                    id="age"
                    type="number"
                    value={profileData.age}
                    onChange={handleChange}
                    readOnly
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="file">Upload Profile Photo</label>
                  <input
                    className="form-control"
                    id="file"
                    type="file"
                    onChange={handleFileChange}
                  />
                </div>

                <button type="submit" className="btn btn-primary w-100 d-flex align-items-center justify-content-center">
                  Complete your profile
                  <i className="bi bi-arrow-right fz-16 ms-1"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </>
  );
};

export default Profile;
