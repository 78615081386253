import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import { BrowserRouter, Routes, Route ,Router} from "react-router-dom";
const Login = () => {
  const navigate = useNavigate();
  const handleLabelClick = (method) => {
    console.log(`Verify by ${method}`);
    // Additional logic based on the verification method can be added here
  };

  useEffect(() => {
    const token = localStorage.getItem('mobile');
   if (token) {
    navigate('/Home');
    }
    const handlePhoneClick = () => {
     navigate('/Otp');
    };

    const handleCardClick = () => {
      navigate('/Qrcode');
    };

    const phoneElement = document.getElementById('Individualphone');
    const cardElement = document.getElementById('Individualcard');
    phoneElement.addEventListener('click', handlePhoneClick);
    cardElement.addEventListener('click', handleCardClick);

    return () => {
      phoneElement.removeEventListener('click', handlePhoneClick);
      cardElement.removeEventListener('click', handleCardClick);
    };
  }, []);

  return (
   <>
  
  
  <div className="login-back-button">
      {/* <Link to="/Home">
        <i className="bi bi-arrow-left-short"></i>
      </Link> */}
</div >
<div className="login-wrapper d-flex align-items-center justify-content-center">
      <div className="custom-container">
        <div className="text-center px-4">
          <img className="login-intro-img" src="img/bg-img/36.png" alt="" />
        </div>

        <div className="register-form mt-4">
          <h3 className="mb-3 text-left">Choose your preferred login method</h3>
          <div className="single-plan-check shadow-sm active-effect active">
            <div className="form-check mb-0">
              <input className="form-check-input" type="radio" name="planChoose" id="Individualphone" />
              <label className="form-check-label" htmlFor="Individualphone" onClick={() => handleLabelClick('Phone number')}>
                Verify by Phone number
              </label>
            </div>
            <i className="bi bi-phone text-primary fz-20 ms-auto"></i>
          </div>
          <div className="single-plan-check shadow-sm active-effect active">
            <div className="form-check mb-0">
              <input className="form-check-input" type="radio" name="planChoose" id="Individualcard" />
              <label className="form-check-label" htmlFor="Individualcard" onClick={() => handleLabelClick('Yojana card')}>
                Verify by Yojana card
              </label>
            </div>
            <i className="bi bi-pass text-primary fz-20 ms-auto"></i>
          </div>
        </div>

        {/* <div className="login-meta-data text-center">
          <Link className="stretched-link forgot-password d-block mt-3 mb-1" to="/forget-password">Forgot Password?</Link>
          <p className="mb-0">Didn't have an account? <Link className="stretched-link" to="/Registration">Register Now</Link></p>
        </div> */}
      </div>
    </div>
   

   
 
   </>
  );
};

export default Login;
