// Supervisorlist.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Settingpop from './Settingpop';
import HomeFooter from './HomeFooter';
import LoadingN from './Loader';
import { API_BASE_URL } from './Config';
import NotificationExample, { notify } from './NotificationExample';
import { Sheet } from 'react-modal-sheet';
import Rating from './Rating'; // Import the Rating component
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

const Supervisorlist = () => {
  const location = useLocation();
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [currentProfileId, setCurrentProfileId] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [showDetails, setShowDetails] = useState(false);
  const [yojananame, setYojananame] = useState('');
  const [yojanaid, setYojanaid] = useState('');
  const [noDataMessage, setNoDataMessage] = useState('');
  const [buttonname, setButtonname] = useState('');
  const [typename, setTypename] = useState('');
  const [userProfileid, setProfileid] = useState('');
  const [urltype, setUrltype] = useState('Changeprofile');
  const [appliedIds, setAppliedIds] = useState([]);
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const searchParams = new URLSearchParams(location.search);
      const yojana = searchParams.get('yojana');
      const yojanaid = searchParams.get('getid');
      const pagetype = searchParams.get('type');
      setYojananame(yojana);
      setYojanaid(yojanaid);
      if (pagetype !== "card") {
        setButtonname('Apply for scheme');
        setTypename('yojana');
        setUrltype('Yojanalist');
      } else {
        setButtonname('Apply for card');
        setTypename('card');
        setUrltype('Changeprofile');
      }
      const pincode = localStorage.getItem('pincode');

      const response = await fetch(`${API_BASE_URL}/supervisor/supervisoraloted?pincode=${pincode}&type=${pagetype}`);
      const result = await response.json();

      if (result && result.length > 0) {
        setProfiles(Array.isArray(result) ? result : [result]);
        const currentProfile = localStorage.getItem('regid');
        setCurrentProfileId(currentProfile);

      } else {
        setNoDataMessage('No supervisors are available in your location.');
        //  notify('info', 'No data found');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      notify('error', 'Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem('regid');
    const pincode = localStorage.getItem('pincode');

    setProfileid(userId);
    if (pincode == 'null' || pincode === "") {
      setShowModal(true);
      return;
    }
    fetchData();

  }, []);

  const handleCancleCard = async (profileId) => {
    const userId = localStorage.getItem('regid');
    if (yojanaid) {
      try {
        const response = await fetch(`${API_BASE_URL}/Supervisor/YojanaapplyCancel`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userId,
            profileId: profileId,
            'type': 'yojana',
            yojanaid: yojanaid
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to apply for card');
        }

        const responseData = await response.json();
        notify('success', responseData.success);
        setAppliedIds((prevIds) => prevIds.filter((appliedId) => appliedId !== profileId));
      } catch (error) {
        notify('error', 'Already Applied for Card');
      }
    } else {
      try {
        const response = await fetch(`${API_BASE_URL}/Supervisor/cardapplyCancel`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userId,
            profileId: profileId,
            'type': 'card',
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to apply for card');
        }

        const responseData = await response.json();
        notify('danger', responseData.success);
        setAppliedIds((prevIds) => prevIds.filter((appliedId) => appliedId !== profileId));
      } catch (error) {
        notify('error', 'Already Applied for Card');
      }
    }
  };

  const handleApplyCard = async (profileId) => {
    const userId = localStorage.getItem('regid');

    if (yojanaid) {
      try {
        const response = await fetch(`${API_BASE_URL}/Supervisor/SchemeapplySave`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userId,
            profileId: profileId,
            'type': 'yojana',
            yojanaid: yojanaid
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to apply for card');
        }

        const responseData = await response.json();
        notify('success', responseData.success);
        setAppliedIds((prevIds) => [...prevIds, profileId]);
      } catch (error) {
        notify('error', 'Already Applied for Card');
      }
    } else {
      try {
        const response = await fetch(`${API_BASE_URL}/Supervisor/cardapplySave`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userId,
            profileId: profileId,
            'type': 'card',
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to apply for card');
        }

        const responseData = await response.json();
        notify('success', responseData.success);
        setAppliedIds((prevIds) => [...prevIds, profileId]);
      } catch (error) {
        notify('error', 'Already Applied for Card');
      }
    }
  };

  const handleNext = () => {
    setShowModal(false);
    navigate('/');
  };

  const toggleDetails = (profileId) => {
    setShowDetails(profileId);
  };
  const toggleDetailsbutton = (id) => {
    setShowDetails((prevDetails) => ({
      ...prevDetails,
      [id]: !prevDetails[id],
    }));
  };
  return (
    <>
      <Settingpop />
      <NotificationExample />
      <div className="header-area" id="headerArea">
        <div className="container">
          <div className="header-content position-relative d-flex align-items-center justify-content-between">
            <div className="back-button">
              <Link to={`/${urltype}`}>
                <i className="bi bi-arrow-left-short"></i>
              </Link>
            </div>
            <div className="page-heading">
              <h2 className="mb-0">Supervisor List</h2>
            </div>
            <div className="setting-wrapper d-flex align-items-center">
              <div className="setting-trigger-btn" id="settingTriggerBtn">
                <Link to="/Language">
                  <i className="bi bi-gear"></i>
                </Link>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="pt-3 d-block mt-5 mb-2">
          {profiles.length > 0 ? (
            <div className="alert alert-info" style={{ textAlign: 'center' }}>
              {yojananame ? (
                <>
                  <div className="check-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="70px" height="70px" viewBox="0 0 20 20">
                      <path fill="#025ce2"
                        d="M10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 Z M10,1.39534884 C5.24778239,1.39534884 1.39534884,5.24778239 1.39534884,10 C1.39534884,14.7522176 5.24778239,18.6046512 10,18.6046512 C14.7522176,18.6046512 18.6046512,14.7522176 18.6046512,10 C18.6046512,5.24778239 14.7522176,1.39534884 10,1.39534884 Z M14.9217059,6.58259135 C15.2023597,6.83364758 15.2263532,7.26468369 14.975297,7.54533745 L10.0661666,13.0332153 C9.79907323,13.3317969 9.33334374,13.336857 9.05982608,13.0441491 L5.71907855,9.46901192 C5.46198377,9.19387916 5.47660645,8.76242348 5.75173921,8.5053287 C6.02687198,8.24823392 6.45832765,8.26285659 6.71542243,8.53798936 L9.54702033,11.5682545 L13.9589598,6.63618243 C14.210016,6.35552866 14.6410521,6.33153512 14.9217059,6.58259135 Z" />
                    </svg>
                  </div>
                  <h1>Congratulations!</h1>
                  <p className="eligibility">Please check your eligibility for the scheme before applying to benefit from it.</p>
                  <Link to="/Yojanalist">
                    <button className="primary-button">See Eligible Schemes</button>
                  </Link>
                  <strong>Booked the Scheme</strong>
                  <p className="eligibility">{yojananame}</p>
                </>
              ) : (
                <>
                  आप योजना कार्ड के लिए पात्र हैं। कृपया निकटतम पर्यवेक्षक को चुनें और योजना के लाभों के बारे में जानें।<br />
                  You are eligible for the scheme card. Please select the nearest supervisor to create the card and learn about the benefits of the scheme.
                </>
              )}
            </div>
          ) : (
            <div className="alert alert-warning">
              {noDataMessage}
            </div>
          )}
        </div>
        {loading && <LoadingN />}
        {profiles.map((profile, index) => {
          const randomColor = getRandomColor();
          return (
            <div className="card timeline-card" key={index} style={{ borderLeft: `0.5rem solid ${randomColor}` }}>
              <div className="card-body">
                <div className="justify-content-between">
                  <div className="timeline-text mb-2">
                    <span className="text-secondary">
                      {profile.staff_city}, {profile.staff_state}, {profile.staff_pincode}
                    </span>
                    <h4>{profile.staff_name}</h4>
                    <h6 style={{ color: 'green' }}>{profile.name} </h6>
                   
                    <Rating rating={profile.rating} /> 
             
                    {showDetails[profile.staff_id] && (
                      <>
                        <small>{profile.staff_nickname} </small><br />
                        <small>Mobile No: {profile.mobile} </small><br />
                        <small>Address: {profile.staff_address} </small>
                      </>
                    )}
                  </div>
                </div>

                {typename == "yojana" ?

                  <div className="timeline-tags d-flex">
                    {profile.supervisor_id === profile.staff_id &&
                      profile.userid === userProfileid &&
                      profile.scheme_id === yojanaid &&
                      profile.apply_type === typename ? (
                        <button
                          className="btn btn-danger me-2"
                          onClick={() => handleCancleCard(profile.staff_id)}
                        >
                          Cancel
                        </button>
                      ) : appliedIds.includes(profile.staff_id) ? (
                        <button
                          className="btn btn-danger me-2"
                          onClick={() => handleCancleCard(profile.staff_id)}
                        >
                          Cancel
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary me-2"
                          onClick={() => handleApplyCard(profile.staff_id)}
                        >
                          {buttonname}
                        </button>
                      )}
                      <button
                        className="btn btn-primary me-2"
                        onClick={() => toggleDetailsbutton(profile.staff_id)}
                      >
                        {showDetails[profile.staff_id] ? (
                          <i className="bi bi-eye"></i>
                        ) : (
                          <i className="bi bi-eye-slash"></i>
                        )}
                      </button>
                    </div>
                  :


                  <div key={profile.staff_id} className="timeline-tags d-flex">
                  {profile.supervisor_id === profile.staff_id &&
                  profile.userid === userProfileid &&
                  profile.apply_type === typename ? (
                    <button
                      className="btn btn-danger me-2"
                      onClick={() => handleCancleCard(profile.staff_id)}
                    >
                      Cancel
                    </button>
                  ) : appliedIds.includes(profile.staff_id) ? (
                    <button
                      className="btn btn-danger me-2"
                      onClick={() => handleCancleCard(profile.staff_id)}
                    >
                      Cancel
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary me-2"
                      onClick={() => handleApplyCard(profile.staff_id)}
                    >
                      {buttonname}
                    </button>
                  )}
                  <button
                    className="btn btn-primary me-2"
                    onClick={() => toggleDetailsbutton(profile.staff_id)}
                  >
                    {showDetails[profile.staff_id] ? (
                      <i className="bi bi-eye"></i>
                    ) : (
                      <i className="bi bi-eye-slash"></i>
                    )}
                  </button>
                </div>
                }
              
              </div>
            </div>
          );
        })}
        <div style={{marginBottom:'100PX'}}></div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Incomplete Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Complete your profile first before you add a beneficiary.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleNext}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
      <Sheet isOpen={showDeleteConfirmation} onClose={() => setShowDeleteConfirmation(false)} detent="content-height">
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div className="p-4">
              <h2>Are you sure you want to delete this account?</h2>
              <div className="d-flex justify-content-end mt-3">
                <button className="btn btn-secondary me-2" onClick={() => setShowDeleteConfirmation(false)}>
                  Cancel
                </button>
                <button className="btn btn-danger">Yes, I want</button>
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
      <HomeFooter />
    </>
  );
};

export default Supervisorlist;
