import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { API_BASE_URL } from './Config';
import './cangrat.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import HomeFooter from "./HomeFooter";
const Cangratulation = () => {
 
  const navigate = useNavigate();
  const location = useLocation();
  const [yojananame, setYojananame] = useState('');
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const yojana = searchParams.get('yojana');
    setYojananame(yojana);
  }, [location.search]);


  return (
    <>
       <div className="header-area" id="headerArea">
          <div className="container">
            <div className="header-content position-relative d-flex align-items-center justify-content-between">
              <div className="back-button">
                <Link to="/Home">
                  <i className="bi bi-arrow-left-short"></i>
                </Link>
              </div>
              <div className="page-heading">
                <h2 className="mb-0">Congratulations</h2>
              </div>
              <div className="setting-wrapper">
                <div className="setting-trigger-btn" id="settingTriggerBtn">
                <Link to="/Language">
                  <i className="bi bi-gear"></i>
                  </Link>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div className="app body2">
                 <div className="container">
                    <div className="check-icon">
                      
                    <svg xmlns="http://www.w3.org/2000/svg" width="70px" height="70px" viewBox="0 0 20 20">
                    <path fill="#025ce2" d="M10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 Z M10,1.39534884 C5.24778239,1.39534884 1.39534884,5.24778239 1.39534884,10 C1.39534884,14.7522176 5.24778239,18.6046512 10,18.6046512 C14.7522176,18.6046512 18.6046512,14.7522176 18.6046512,10 C18.6046512,5.24778239 14.7522176,1.39534884 10,1.39534884 Z M14.9217059,6.58259135 C15.2023597,6.83364758 15.2263532,7.26468369 14.975297,7.54533745 L10.0661666,13.0332153 C9.79907323,13.3317969 9.33334374,13.336857 9.05982608,13.0441491 L5.71907855,9.46901192 C5.46198377,9.19387916 5.47660645,8.76242348 5.75173921,8.5053287 C6.02687198,8.24823392 6.45832765,8.26285659 6.71542243,8.53798936 L9.54702033,11.5682545 L13.9589598,6.63618243 C14.210016,6.35552866 14.6410521,6.33153512 14.9217059,6.58259135 Z"/>
                    </svg>
                    </div>
                    <h1>Congratulations!</h1>
                  
                    {yojananame==null?
              <><p className="eligibility">Please check your eligibility for the scheme</p>
              <p className="eligibility">before applying to benefit from it.</p>
              <Link to="/Yojanalist"> <button className="primary-button">See Eligible Schemes</button></Link> </>
              :

            <>
            <strong>Aapki Yojana: Booked the Scheme</strong>
            <p className="eligibility">{yojananame}</p>
            
            </>
         }
      
        {/* <button className="secondary-button">Continue Screening</button> */}
        <Link to="/Home"><button className="link-button">Go to Home</button></Link> 
        <div className="footer">
        <div className="footer-content">
          <div className="card2-icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 7H3C1.9 7 1 7.9 1 9V15C1 16.1 1.9 17 3 17H21C22.1 17 23 16.1 23 15V9C23 7.9 22.1 7 21 7ZM21 15H3V12H21V15ZM21 9H3V9.98H21V9Z" fill="#fff"/>
            </svg>
          </div>
          <div>
            <p className="footer-title">With YOJANA card</p>
            <p className="footer-subtitle">High Returns, Minimal Efforts</p>
          </div>
          <div className="arrow-icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 4L10.6 5.4L16.2 11H4V13H16.2L10.6 18.6L12 20L20 12L12 4Z" fill="#fff"/>
            </svg>
          </div>
        </div>
      </div>
      </div>
     
    </div>
    <HomeFooter />
    </>
  );
};

export default Cangratulation;
