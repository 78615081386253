import React, { useEffect } from 'react';

const GoogleTranslate = ({ pageLanguage, includedLanguages = 'en,hi' }) => {
  useEffect(() => {
    const googleTranslateElementInit = () => {
      try {
        if (window.google && window.google.translate && window.google.translate.TranslateElement) {
          new window.google.translate.TranslateElement(
            {
              pageLanguage,
              includedLanguages,
              layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            },
            'google_translate_element'
          );
          console.log("Google Translate element initialized");
        } else {
          console.error("Google Translate element initialization failed");
        }
      } catch (error) {
        console.error("Error during Google Translate initialization", error);
      }
    };

    const scriptId = 'google-translate-script';
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.type = 'text/javascript';
      script.async = true;
      script.onload = googleTranslateElementInit;
      script.onerror = () => {
        console.error("Google Translate script failed to load.");
      };
      document.body.appendChild(script);
      console.log("Google Translate script added");
    } else {
      googleTranslateElementInit();
    }

    return () => {
      // Optionally clean up if needed
    };
  }, [pageLanguage, includedLanguages]);

  return <div id="google_translate_element"></div>;
};

export default GoogleTranslate;
